html, body {
  min-height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  margin: 0;
}

#root {
  min-height: 100%;
}

header {
  padding-bottom: 0;
  margin-bottom: 30px;
}

h1 {
  font-size:50px;
  margin-bottom:0;
  font-weight: 900;
}

h2 { margin-top:0; }

ul {
  padding: 0 0 0 20px;
  margin-bottom: 40px
}

ul li {
  list-style-type: circle;
  margin-bottom: 10px;
}

.hidden {
  display:none;
}

img {
  display: block
}

button {
  background: transparent;
  padding: 3px 20px;
  cursor: pointer;
}

article {
  padding: 20px;
  min-height: 100vh;
}

a {
  text-decoration: underline;
}

article * {
  color: inherit;
}

#color-picker-form {
  display: inline-block;
  padding: 18px;
}

#color-picker-form button {
  margin-left: 20px;
  vertical-align: top;
  border: 0;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 29px;
}

#color-picker-form h3 {
  margin-top: 0;
}

#color-picker-form input[type="color"] {
  vertical-align: top;
  height: 35px;
}

.confetti-canvas {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000;
  pointer-events: none;
}

.confetti-canvas--hidden {
  display: none;
}

.confetti-canvas--background {
  z-index: -1;
}

.notifications {
  position: fixed;
  right: 0px;
  top: 0px;
  padding: 10px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.notifications > a {
  margin-bottom: 10px;
  display: block;
  border-radius: 5px;
  padding: 10px 15px;
  line-height: 1.4;
  text-decoration: none;
}

.notifications > a:last-child {
  margin-bottom: 0;
}